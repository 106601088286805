<template>
  <div>
    <div class="father_box">
      <img src="../../src/image/Doorboard.png" class="Doorboard" />
      <div class="introduce1">
        <div class="name">河南鲲航科技有限公司</div>
        <div class="value">
          郑州鲲航网络科技有限公司，作为一家专注于网络科技领域的创新型企业，自成立以来，便致力于网络技术的研发、技术咨询、技术转让以及技术服务等业务。公司凭借强大的技术研发实力和市场洞察力，不断推出具有竞争力的网络解决方案，满足客户的多样化需求。
          在计算机软硬件领域，郑州鲲航不仅提供销售服务，更注重技术开发和创新，通过持续的技术投入和人才培养，确保公司在激烈的市场竞争中保持领先地位。
          此外，公司还涉足广告业务，包括广告设计、制作、代理及发布等，凭借对市场的深刻理解和创意设计能力，为客户提供全方位的广告服务，助力品牌传播。
          郑州鲲航网络科技有限公司以其卓越的技术能力、专业的服务团队和创新的商业模式，在网络科技领域树立了良好的口碑，赢得了广泛的客户认可。未来，公司将继续坚持技术创新，拓展业务领域，为客户提供更加优质、高效的网络科技解决方案。
        </div>
      </div>
    </div>
    <div class="photo">
      <img src="../../src/image/CompanyPhotos4.jpg" />
      <img src="../../src/image/CompanyPhotos1.png" />
      <img src="../../src/image/CompanyPhotos2.png" />
      <img src="../../src/image/CompanyPhotos3.png" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.father_box {
  width: 43rem;
  margin: 3rem auto 0;
  position: relative;
}

.Doorboard {
  width: 21rem;
  /* height: 15.6rem; */
  position: relative;
  z-index: 1;
  border-radius: 0.6rem;
}

.introduce1 {
  width: 28.8rem;
  /* height: 16.8rem; */
  background: linear-gradient(
    153deg,
    rgba(253, 253, 253, 0.1) 0%,
    #fbfbfb 100%
  );
  position: relative;
  z-index: 3;
  padding: 2.31rem 1.56rem;
  float: right;
  margin-top: -10rem;
  border-radius: 0.9rem;
  backdrop-filter: blur(10px);
}

.introduce1 > .name {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  font-size: 1.32rem;
  font-weight: 500;
}

.value {
  color: #333333;
  font-size: 0.9rem;
  margin-top: 1.5rem;
  font-weight: 500;
  text-align: left;
}

.photo {
  width: 43rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding-top: 1.8rem;
  padding-bottom: 3rem;
}

.photo > img {
  width: 10rem;
  height: 6.2rem;
  border-radius: 0.42rem;
}
</style>
