import axios from "axios";
import router from "../router/router";
//创建axios实例，封装成request简写代码
const request = axios.create({
  // `baseURL` 将自动加在 `url` 前面，除非 `url` 是一个绝对 URL。
  // 它可以通过设置一个 `baseURL` 便于为 axios 实例的方法传递相对 URL
  baseURL: "https://khwlkj.cn/api", // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
  // baseURL: 'https://fxsc.xdgm21.com',  // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
  // `timeout` 指定请求超时的毫秒数(0 表示无超时时间)
  // 如果请求花费了超过 `timeout` 的时间，请求将被中断
  timeout: 5000,
});

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
let timer;
let time = 1800000; //30分钟自动清除userinfo
request.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json;charset=utf-8";
    // if(sessionStorage.getItem('userinfo')){
    //     config.headers.token = JSON.parse(sessionStorage.getItem('userinfo')).token;  // 设置请求头
    // }
    // console.log(config.url)
    // config.url = config.url + '?lang=' + lang
    // clearTimeout(timer)
    // timer = setTimeout(() => {
    //     sessionStorage.setItem('userinfo', '');
    // }, time);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
  (response) => {
    let res = response.data;

    // 如果是返回的文件
    //Blob(Binary long Object)是二进制长对象的意思,Blob通常用于存储大文件,典型的Blob内容是一张图片或者一个声音文件
    if (response.config.responseType === "blob") {
      return res;
    }
    // 兼容服务端返回的字符串数据
    if (typeof res === "string") {
      res = res ? JSON.parse(res) : res;
    }
    return res;
  },
  (error) => {
    console.log("error: ", error);
    if (error.response?.data.code === 401) {
      // ElementUI.$message.error(error.response.data.msg);
    }
    return Promise.reject(error);
  }
);
export default request;
