<template>
	<dir>
		<div class="title">
			<img src="../../src/image/left.png" class="left" />
			<div class="titleValue">{{name}}</div>
			<img src="../../src/image/right.png" class="right" />
		</div>
		<div class="remarks">{{remarks}}</div>
	</dir>
</template>

<script>
	export default {
		name: 'titles',
		components: {

		},
		props: ['name', 'remarks'],
		data() {
			return {

			}
		}
	}
</script>

<style scoped>
	.title {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 3rem;
		min-width: 42rem;
		margin-bottom: 0.6rem;
	}

	.left {
		width: 3.6rem;
		height: 0.36rem;
	}

	.titleValue {
		font-size: 1.3rem;
		color: #333333;
		margin-right: 1.2rem;
		margin-left: 1.2rem;
	}

	.right {
		width: 3.6rem;
		height: 0.36rem;
	}

	.remarks {
		color: #999999;
		font-size: 0.9rem;
		max-width: 42rem;
		text-align: center;
		margin: 0.6rem auto 0;
		min-width: 42rem;
	}
</style>