<template>
  <div class="bottom_form">
    <div class="left_form">
      <div class="title">多一份参考，总有收益</div>
      <div class="explain">留下您的联系方式，获取项目报价</div>
      <div class="input_box">
        <span>电话：</span>
        <div>
          <input type="text" v-model="mobile" />
        </div>
      </div>
      <div class="input_box">
        <span>需求：</span>
        <div>
          <input type="text" v-model="demand" />
        </div>
      </div>
      <div class="btns">
        <div @click="CustomerService">在线咨询</div>
        <div @click="submitForm">立即提交</div>
      </div>
    </div>
    <div class="right_box">
      <div class="title">联系我们</div>
      <div class="code_box">
        <div class="flex-c">
          <div class="code">
            <img src="../../src/image/image.png" />
          </div>
          <span>扫码添加客服，获取精准报价</span>
        </div>
        <div>
          <div class="phone">
            <img src="../../src/image/phone.png" class="phone_icon" />
            <span style="white-space: nowrap"
              >7*24H服务热线：
              <span style="font-weight: bold; font-size: 0.9rem"
                >15303892976</span
              >
            </span>
          </div>
          <div class="address">
            <img src="../../src/image/address.png" class="address_icon" />
            <span
              >公司地址：郑州市金水区文化路北三环交叉口瀚海爱特中心B塔21楼2215</span
            >
          </div>
        </div>
      </div>
      <div class="copyright">
        <span>版权所有 郑州鲲航网络科技有限公司</span>
        <span style="cursor: pointer" @click="goGH"
          >备案号：豫ICP备2023032597号</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mobile: "",
      demand: "",
    };
  },
  methods: {
    // 点击前往工信部
    goGH() {
      window.open("https://beian.miit.gov.cn/");
    },
    CustomerService() {
      window.open("https://travel.khwlkj.cn/chat.html");
    },
    submitForm() {
      console.log(this.demand, this.mobile);
      if (this.mobile == "") {
        this.$notify.error({
          title: "错误",
          message: "手机号不能为空",
          customClass: "notify-error",
        });
        return false;
      }
      const regex = /^1[3-9]\d{9}$/;
      if (!regex.test(this.mobile)) {
        this.$notify.error({
          title: "错误",
          message: "手机号格式不正确",
          customClass: "notify-error",
        });
        return false;
      }
      if (this.demand == "") {
        this.$notify.error({
          title: "错误",
          message: "需求不能为空",
          customClass: "notify-error",
        });
        return false;
      }
      let data = {
        mobile: this.mobile,
        demand: this.demand,
      };
      this.request.post("/index/consult", data).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: "提交成功",
            type: "success",
          });
          this.mobile = "";
          this.demand = "";
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style scoped>
.bottom_form {
  width: 100%;
  padding: 0.9rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.left_form {
  /* width: 46%; */
  margin-right: 3rem;
}

.title {
  color: #333333;
  font-size: 1.32rem;
  font-weight: 500;
  text-align: left;
}

.explain {
  color: #999999;
  font-size: 0.9rem;
  margin-top: 0.9rem;
  text-align: left;
}

.input_box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1.5rem;
}

.input_box > span {
  font-size: 1.08rem;
  line-height: 1.5rem;
  color: #333333;
  white-space: nowrap;
}

.input_box > div {
  width: 18rem;
  height: 2.4rem;
  border: 0.06rem solid #bfbfbf;
  border-radius: 0.36rem;
  padding-left: 0.7rem;
}

input {
  border: none;
  outline: none;
  background-color: transparent;
}

.input_box > div > input {
  width: 96%;
  height: 100%;
  font-size: 0.9rem;
}

.btns {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 2.1rem;
  cursor: pointer;
}

.btns > div {
  width: 7.68rem;
  height: 2.4rem;
  border-radius: 0.36rem;
  border: 0.06rem solid #bfbfbf;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7a7979;
  font-size: 0.9rem;
}

.btns > div:hover {
  background-color: #13be88;
  color: #ffffff;
  border: 0.06rem solid #13be88;
}

.right_box {
  /* width: 50%; */
}

.copyright {
  color: #333333;
  font-size: 0.9rem;
  width: 100%;
  text-align: left;
  margin-top: 0.9rem;
  white-space: nowrap;
}

.code_box {
  display: flex;
  align-items: center;
  margin-top: 0.74rem;
}

.flex-c {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1.02rem;
}

.code {
  width: 9rem;
  height: 9rem;
  border: 0.06rem solid #e5e5e5;
  padding: 0.23rem 0 0;
  border-radius: 0.6rem;
}

.code > img {
  width: 100%;
  height: 100%;
  border-radius: 0.6rem;
}

.flex-c > span {
  color: #999999;
  font-size: 0.66rem;
  margin-top: 0.3rem;
  text-align: center;
}

.phone {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.phone_icon {
  width: 0.66rem;
  height: 0.66rem;
  margin-right: 0.18rem;
}

.phone > span {
  color: #333333;
  font-size: 0.9rem;
}

.address {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin-top: 0.9rem;
}

.address_icon {
  width: 0.66rem;
  height: 0.66rem;
  margin-right: 0.18rem;
  margin-top: 0.34rem;
  display: block;
}

.address > span {
  color: #333333;
  font-size: 0.9rem;
  text-align: left;
}
</style>
